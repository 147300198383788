import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from '@mui/material';
import * as yup from 'yup';

import { IndexParameterInput } from '@gameonly/core';
import { useFormik } from 'formik';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useProjects from '../project/context/useProjects';
import TextBlock from '../results/TextBlock';
import Button from '../ui/Button';
import useConfiguration from '../useConfiguration';
import useReports from './context/useReports';
import keyify from '../project/parameters/keyify';

interface CreateReportModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: any;
  onSave?: (values: IndexParameterInput[], modelType: string) => void;
}

const validationSchema = yup.object({
  name: yup.string().required('Champ obligatoire'),
  projects: yup.array().required('Champ obligatoire'),
});

const CreateReportModal = ({
  open,
  setOpen,
  onSave,
  onClose,
}: CreateReportModalProps) => {
  const { t } = useTranslation(['annual-reports', 'common', 'model']);
  const navigate = useNavigate();
  const called = useRef(false);
  const autofocusFirst = useRef(true);
  const { create } = useReports();
  const { projects } = useProjects();
  const { configuration } = useConfiguration();

  const formik = useFormik({
    initialValues: {
      name: '',
      projects: projects?.map((p) => p._id.toString()) as string[],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await create({
        name: values.name,
        projectIds: values.projects,
      });
      handleClose();
      navigate(`/reports/${result._id}`);
    },
  });

  const handleClose = () => {
    called.current = false;
    autofocusFirst.current = true;
    onClose && onClose();
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" id="create_modal">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="scroll-dialog-title">
          {t('annual-reports:creation-modal.title')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
            component="div"
          >
            <TextBlock
              className="small"
              result={{
                type: 'text',
                text: t('annual-reports:creation-modal.explanation'),
              }}
              translate={false}
            ></TextBlock>
            <TextField
              id="name"
              name="name"
              label={t('annual-reports:creation-modal.name')}
              autoFocus
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />

            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">
                {t('annual-reports:creation-modal.projects-selector')}
              </FormLabel>
              <FormGroup>
                {projects?.map((project) => {
                  const singularName = t(
                    `model:${keyify(project.model.name)}.singularName`,
                  );
                  return (
                    <FormControlLabel
                      key={project._id.toString()}
                      control={
                        <Checkbox
                          checked={formik.values.projects.includes(
                            project._id.toString(),
                          )}
                          onChange={formik.handleChange}
                          value={project._id.toString()}
                          name="projects"
                        />
                      }
                      label={`${singularName} - ${project.name}`}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ position: 'sticky', bottom: 0, background: 'white' }}
        >
          <Button onClick={handleCancel}>{t('common:cancel')}</Button>
          <Button type="submit" variant="contained" color="primary">
            {t('annual-reports:creation-modal.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateReportModal;
