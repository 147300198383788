import styled from '@emotion/styled';
import { Model, TableResult } from 'packages/core/dist/project/types';
import { useTranslation } from 'react-i18next';
import keyify from '../project/parameters/keyify';
import Markdown from '../ui/Markdown';
import { GraphTitle } from './ResultGraph';

const Container = styled.div`
  table {
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-spacing: 0;
    border-collapse: separate;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;

    thead {
      text-align: right;

      tr {
        border-radius: 10px;
        background-color: var(--green);
        color: #ffffff;
        th {
          padding: 8px 15px;
          border-radius: 10px;
        }

        th:first-of-type {
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 8px 15px;
          text-align: right;
        }

        td:first-of-type {
          text-align: left;
        }

        border-bottom: 1px solid #dddddd;

        &.category {
          background-color: var(--lightgreen);
          font-weight: bold;
        }

        &:hover:not(.category) {
          background-color: var(--color-primary-light);
        }

        &:last-of-type {
          td {
            border-bottom: 2px solid #009879;
          }
        }
      }
    }
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  background-color: white;
  position: relative;

  > p:first-of-type:before {
    left: 0;
  }
`;

const isCategory = (row: string[]) => {
  return row.slice(1).every((value) => !value);
};

const Table = ({ result, model }: { result: TableResult; model?: Model }) => {
  const { t } = useTranslation(['model']);
  if (!result.table || !result.table.length) {
    return null;
  }
  const headers = result.table[0];
  const data = result.table.slice(1);
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${result.code?.toLowerCase()}`;
  return (
    <Container>
      <TitleContainer>
        <GraphTitle
          text={t(`${translationPrefix}.title`) || ''}
          size={'full'}
          color={'default'}
        />
      </TitleContainer>
      {t(`${translationPrefix}.description`) && (
        <Markdown>{t(`${translationPrefix}.description`)}</Markdown>
      )}
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th>{t(`${translationPrefix}.data.${keyify(header)}`)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => {
            if (isCategory(row)) {
              return (
                <tr className="category">
                  <td colSpan={row.length}>
                    {t(`${translationPrefix}.data.${keyify(row[0])}`)}
                  </td>
                </tr>
              );
            }
            return (
              <tr>
                {row.map((value, index) => {
                  if (index === 0) {
                    return (
                      <td>{t(`${translationPrefix}.data.${keyify(value)}`)}</td>
                    );
                  }
                  return <td>{value}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
};

export default Table;
