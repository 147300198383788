import styled from '@emotion/styled';
import { ReactNode, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import useUser from '../authentication/context/useUser';
import { getProjectTitle, useProject } from '../project/context/useProject';

import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import Tooltip from '@mui/material/Tooltip';
import ShareProject from '../project/ShareProject';
import { ReactComponent as Export } from '../ui/icons/export.svg';
import { ReactComponent as Home } from '../ui/icons/home.svg';
import { ReactComponent as Next } from '../ui/icons/next.svg';

import { Project, ProjectUser } from '@gameonly/core';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { authenticatedFetch } from '../authentication/authenticatedFetch';
import LanguageSwitcher from './LanguageSwitcher';
import UserMenu from './UserMenu';
import keyify from '../project/parameters/keyify';

export const HeaderContainer = styled.header`
  height: 64px;
  width: 100%;
  position: sticky;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  top: 0;
  background-color: white;
  z-index: 80;
`;

export const LogoWrapper = styled.img`
  max-width: 150px;
  max-height: 60%;
  cursor: pointer;
`;
export const ConsortiumWrapper = styled.img`
  max-width: 180px;
  max-height: 100%;
`;

export const HeaderProject = () => {
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const { user } = useUser();
  const isShareProject = project?.users.some(
    (u: ProjectUser) => u.id === user?._id && u.role === 'owner',
  );
  return (
    <HeaderContainer>
      <HeaderSection>
        {project && <ProjectBreadcrumb project={project!} />}
      </HeaderSection>
      <HeaderSection marginBetween="16px">
        {isShareProject && project && <ShareButton project={project} />}
        <LanguageSwitcher />
        <UserMenu />
      </HeaderSection>
    </HeaderContainer>
  );
};

export const HeaderProjects = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeaderSection>
        <ConsortiumWrapper
          src="/logo_consortium.png"
          alt="logo"
          onClick={() => navigate('/')}
        />
      </HeaderSection>
      <HeaderSection centered>
        <LogoWrapper
          id="logoWrapper"
          src="/logo.png"
          alt="logo"
          onClick={() => navigate('/')}
        />
      </HeaderSection>
      <HeaderSection marginBetween="16px">
        <LanguageSwitcher />
        <UserMenu />
      </HeaderSection>
    </HeaderContainer>
  );
};

const ProjectBreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ProjectBreadcrumb = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['project']);
  const projectTitle = getProjectTitle(
    project,
    t(`model:${keyify(project?.model.name)}.defaultTitle`),
  );
  const isResults = location.pathname.includes('/results');
  const isActions = location.pathname.includes('/actions');
  const isTour = location.pathname.includes('/tour');
  const isProject = !isResults && !isActions && !isTour;
  return (
    <ProjectBreadcrumbWrapper>
      <IconContainer onClick={() => navigate('/')}>
        <Home />
      </IconContainer>
      <IconContainer>
        <Next />
      </IconContainer>
      {isProject ? (
        <p className="h6r">{projectTitle}</p>
      ) : (
        <Link to={`/project/${project._id}`} className="h6r underline">
          {projectTitle}
        </Link>
      )}
      {isResults && (
        <>
          <IconContainer>
            <Next />
          </IconContainer>
          <p className="h6b">{t('project:results-page.title')}</p>
        </>
      )}
      {isActions && (
        <>
          <IconContainer>
            <Next />
          </IconContainer>
          <p className="h6b">Actions</p>
        </>
      )}
      {isTour && (
        <>
          <IconContainer>
            <Next />
          </IconContainer>
          <p className="h6b" data-tour="tour-transport-title">
            Calculateur Mobilité
          </p>
        </>
      )}
    </ProjectBreadcrumbWrapper>
  );
};

type HeaderSectionProps = {
  centered?: boolean;
  marginBetween?: string;
  children: ReactNode;
};

const HeaderSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: ${(props: HeaderSectionProps) =>
    props.centered ? 'absolute' : ''};
  left: ${(props: HeaderSectionProps) => (props.centered ? '50%' : '')};
  transform: ${(props: HeaderSectionProps) =>
    props.centered ? 'translate(-50%, 0)' : ''};
  > *:not(:last-child) {
    margin-right: ${(props: HeaderSectionProps) =>
      props.marginBetween ? props.marginBetween : ''};
  }
`;

export const HeaderSection = ({
  marginBetween,
  centered,
  children,
}: HeaderSectionProps) => {
  return (
    <HeaderSectionWrapper marginBetween={marginBetween} centered={centered}>
      {children}
    </HeaderSectionWrapper>
  );
};

const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const downloadFile = (blob: Blob, fileName: string) => {
  const anchorElement = document.createElement('a');

  document.body.appendChild(anchorElement);

  anchorElement.style.display = 'none';

  const url = window.URL.createObjectURL(blob);

  anchorElement.href = url;
  anchorElement.download = fileName;
  anchorElement.click();

  window.URL.revokeObjectURL(url);
};

const ShareButton = ({ project }: { project: Project }) => {
  const { t } = useTranslation(['project']);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const handleShareProject = () => {
    setOpenShare(true);
  };
  const handleDownloadProject = async () => {
    setLoading(true);

    const response = await authenticatedFetch(
      `${process.env.REACT_APP_API_URL}/projects/${project._id}`,
      {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/vnd.ms-excel',
          'Content-Type': 'application/json',
        }),
      },
    );
    const statisticFileBlob = await response.blob();

    const filename = `project_${project._id}_${new Date().toISOString()}.xlsx`;

    setLoading(false);
    downloadFile(statisticFileBlob, filename);
    setLoading(false);
  };
  return (
    <>
      <ShareProject
        open={openShare}
        onClose={() => setOpenShare(false)}
        project={project}
      />
      <Tooltip title={t('project:share-button.tooltip')}>
        <HeaderButton onClick={handleShareProject}>
          <IconContainer>
            <Export />
          </IconContainer>
          <p className="h6r">{t('project:share-button.label')}</p>
        </HeaderButton>
      </Tooltip>
      <Tooltip title={t('project:download-button.tooltip')}>
        <HeaderButton onClick={handleDownloadProject}>
          <IconContainer>
            <DownloadForOfflineOutlinedIcon />
          </IconContainer>
          <p className="h6r">{t('project:download-button.label')}</p>
          {loading && <CircularProgress sx={{ maxWidth: 20, maxHeight: 20 }} />}
        </HeaderButton>
      </Tooltip>
    </>
  );
};
