import styled from '@emotion/styled';
import { Model, Parameter, Value } from '@gameonly/core';
import { useState } from 'react';
import { ReactComponent as BaseHelp } from '../ui/icons/help.svg';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';

import Markdown from '../ui/Markdown';

import { useTranslation } from 'react-i18next';
import ParameterFactory from './parameters/Factory';
import keyify from './parameters/keyify';
import ModelReferenceMultiSelect from './parameters/ModelReferenceMultiSelect';

const Help = styled(BaseHelp)`
  cursor: pointer;
`;

const ParametersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 16px 0;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  margin-bottom: 0;
`;

const Parameters = ({
  autofocusFirst,
  parameters,
  onUpdateParameter,
  style,
  debounced = true,
  model,
  isValidationVisible,
}: {
  autofocusFirst: boolean;
  parameters: Parameter[];
  onUpdateParameter: (index: number, value: Value) => void;
  style?: any;
  debounced?: boolean;
  model: Model;
  isValidationVisible: boolean;
}) => {
  return (
    <ParametersWrapper style={style}>
      {parameters.map((parameter: Parameter, index: number) => (
        <ParameterComponent
          id={`parameter-${parameter.index}`}
          key={`${parameter.index}`}
          autofocus={autofocusFirst && index === 0}
          parameter={parameter}
          onUpdateParameter={onUpdateParameter}
          debounced={debounced}
          model={model}
          isValidationVisible={isValidationVisible}
        />
      ))}
    </ParametersWrapper>
  );
};

const ParameterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  scroll-padding-top: 2500px;
  transition: background 0.3s ease-in-out;
  padding: 16px;
  border-radius: 8px;
  background-opacity: 0.5;

  &.blink {
    background: var(--lightgreen);
  }

  .parameter_input {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    font-style: italic;
  }

  &.explanation,
  &.import_project {
    grid-column: 1 / -1;

    > .hxr {
      display: none;
    }
  }

  &.model-reference {
    grid-column: 1 / -1;
  }
`;

const ParameterTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    cursor: pointer;
    flex-shrink: 0;
  }
  .import_project & {
    display: none;
  }
`;

interface ParameterComponentProps {
  id?: string;
  autofocus: boolean;
  parameter: Parameter;
  onUpdateParameter: (index: number, value: Value) => void;
  debounced: boolean;
  model: Model;
  isValidationVisible: boolean;
}

const ParameterComponent = ({
  id,
  autofocus,
  parameter,
  onUpdateParameter,
  debounced,
  model,
  isValidationVisible,
}: ParameterComponentProps) => {
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const { t, i18n } = useTranslation(['model']);
  const translationPrefix = `model:${keyify(model.name)}.parameter.${keyify(
    parameter.id || '',
  )}`;
  return (
    <>
      {i18n.exists(`${translationPrefix}.information`) ? (
        <ParameterInfoModal
          open={openInfo}
          setOpen={setOpenInfo}
          name={t(`${translationPrefix}.name`)}
          information={t(`${translationPrefix}.information`) || ''}
        />
      ) : null}
      {parameter.modelReference && (
        <>
          <ParameterWrapper
            className={
              (parameter.modelReference ? 'model-reference' : '') +
              ' ' +
              (parameter.type ? parameter.type : '')
            }
          >
            <ModelReferenceMultiSelect
              parameter={parameter}
              onUpdateParameter={onUpdateParameter}
            >
              <ParameterTitle>
                <p className="hzb">{t(`${translationPrefix}.description`)}</p>
                <RequiredIcon
                  isValidationVisible={isValidationVisible}
                  parameter={parameter}
                />
              </ParameterTitle>
              <ParameterFactory
                autofocus={autofocus}
                parameter={parameter}
                onUpdateParameter={onUpdateParameter}
                model={model}
              />
              {i18n.exists(`${translationPrefix}.information`) &&
                !!t(`${translationPrefix}.information`) && (
                  <Help onClick={() => setOpenInfo(true)} />
                )}
            </ModelReferenceMultiSelect>
          </ParameterWrapper>
        </>
      )}
      {!parameter.modelReference && (
        <ParameterWrapper className={parameter.type}>
          <div id={id}></div>
          <ParameterTitle>
            <p className="hxc">{t(`${translationPrefix}.name`)}</p>
            <RequiredIcon
              parameter={parameter}
              isValidationVisible={isValidationVisible}
            />
            <InformationIcon
              parameter={parameter}
            />
            {i18n.exists(`${translationPrefix}.information`) &&
              !!t(`${translationPrefix}.information`) && (
                <Help onClick={() => setOpenInfo(true)} />
              )}
          </ParameterTitle>
          <p className="hxr">{t(`${translationPrefix}.description`)}</p>
          <ParameterFactory
            autofocus={autofocus}
            parameter={parameter}
            onUpdateParameter={onUpdateParameter}
            model={model}
          />
        </ParameterWrapper>
      )}
    </>
  );
};

interface ParameterInfoModalProps {
  open: boolean;
  name: string | undefined;
  information: string;
  setOpen: (open: boolean) => void;
}

const ParameterInfoModal = ({
  open,
  name,
  information,
  setOpen,
}: ParameterInfoModalProps) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" id="create_modal">
      <DialogTitle id="scroll-dialog-title">{name}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          sx={{
            fontWeight: '400',
            fontSize: '16px',
            fontFamily: "'Montserrat', sans-serif",
            margin: '16px 0 32px',
          }}
        >
          <Markdown>{information}</Markdown>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ position: 'sticky', bottom: 0, background: 'white' }}
      >
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

const InformationIcon = ({
  parameter,
}: {
  parameter: Parameter;
}) => {
  const { t, i18n } = useTranslation(['project']);


  const fontSize = 'small';

  if (parameter.type === "measureType") {
    const information = t(`project:parameter.information.${parameter.type}`);
    return (
      <Tooltip title={information}>
        <InfoOutlinedIcon fontSize={fontSize} />
      </Tooltip>
    );
  }
  return null;
};

const RequiredIcon = ({
  parameter,
  isValidationVisible,
}: {
  parameter: Parameter;
  isValidationVisible: boolean;
}) => {
  const { t, i18n } = useTranslation(['project']);

  if (!parameter.required) return <></>

  const isError = parameter.valueError && parameter.valueError > 0;
  const fontSize = 'small';

  if (!isValidationVisible) {
    const requiredMessage = t(`project:parameter.requiredMessage`);
    return (
      <Tooltip title={requiredMessage}>
        <ErrorOutlineOutlinedIcon color="primary" fontSize={fontSize} />
      </Tooltip>
    );
  }
  if (!isError) {
    const okMessage = t(`project:parameter.error-okMessage`);
    return (
      <Tooltip title={okMessage}>
        <CheckCircleOutlineOutlinedIcon color="success" fontSize={fontSize} />
      </Tooltip>
    );
  }
  const errorCode = parameter.valueError?.toString();
  const errorMessage = errorCode
    ? t(`project:parameter.error-${errorCode}-errorMessage`)
    : null;
  return (
    <Tooltip title={errorMessage}>
      <ErrorOutlineOutlinedIcon color="warning" fontSize={fontSize} />
    </Tooltip>
  );
};

export default Parameters;
