import styled from '@emotion/styled';
import { Model, Nav, NavElement } from '@gameonly/core';
import { useTranslation } from 'react-i18next';
import { SortButton } from '../ui/Button';
import { scopeIcons } from './ResultsSimple';

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 8px;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
`;

const NavComponent = ({ result, model }: { result: Nav; model?: Model }) => {
  const { t } = useTranslation(['project', 'model']);
  if (result.data.length === 0) return null;
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${
    result.code?.toLowerCase()
  }`;
  const titles = t(`${translationPrefix}.titles`).split(',');
  return (
    <Container>
      {result.data.map((element, index) => (
        <NavElementComponent
          key={element.title}
          element={{ ...element, title: titles[index] }}
        />
      ))}
    </Container>
  );
};

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-shrink: 1;
  align-items: center;
`;

const NavElementComponent = ({ element }: { element: NavElement }) => {
  const { link, title, icon } = element;
  const elementIcon = scopeIcons[icon];
  return (
    <a href={`#results_${link}`}>
      <SortButton
        startIcon={<IconContainer>{elementIcon}</IconContainer>}
        className={'result'}
      >
        {title}
      </SortButton>
    </a>
  );
};

export default NavComponent;
