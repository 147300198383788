import styled from '@emotion/styled';
import { Model, TextResult } from '@gameonly/core';
import { useTranslation } from 'react-i18next';
import Markdown from '../ui/Markdown';

const TextBlockContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  color: black;
  margin-bottom: 16px;
  background-color: var(--color-primary-light);

  > img:first-of-type {
    width: 240px;
  }

  a {
    color: var(--green) !important;
    text-decoration: underline !important;
  }

  &.small {
    > img:first-of-type {
      width: 120px;
    }
  }
`;

const TextBlock = ({
  result,
  model,
  className,
  translate = true,
}: {
  result: TextResult;
  model?: Model;
  className?: string;
  translate?: boolean;
}) => {
  const { t } = useTranslation(['project', 'model']);
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${result.code?.toLowerCase()}`;
  return (
    <TextBlockContainer className={className}>
      <img src="/logo-small.png" alt="" />
      <Markdown>
        {translate ? t(`${translationPrefix}.text`) : result.text || ''}
      </Markdown>
    </TextBlockContainer>
  );
};

export default TextBlock;
