import styled from '@emotion/styled';
import { SyntheticEvent, useState } from 'react';

import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Location as RouterLocation,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import LanguageSwitcher from '../layout/LanguageSwitcher';
import TextField from '../project/parameters/TextField';
import BaseBlock from '../ui/Block';
import LoadingButton from '../ui/LoadingButton';
import { useAuthentication } from './context/useAuthentication';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Block = styled(BaseBlock)`
  display: flex;
  width: 400px;
  flex-direction: column;
  gap: 16px;
  padding: 16px 32px 38px;
`;

const ErrorBlock = styled(BaseBlock)`
  width: 100%;
`;

const SideActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 0.8rem;
  margin-top: 16px;
  align-items: center;
`;

const LanguageSwitcherContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Login = () => {
  const { loading, login, error: authError } = useAuthentication();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation(['authentication']);

  let originLocation: RouterLocation | null = null;
  let error: string | null = null;
  if (state) {
    if ((state as any).originLocation) {
      originLocation = (state as any).originLocation;
    }
    if ((state as any).error) {
      error = (state as any).error;
    }
  }

  const handleFormSubmitted = async (
    event: SyntheticEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    await login(email, password);
    navigate(originLocation ?? '/');
  };

  return (
    <>
      <LanguageSwitcherContainer>
        <LanguageSwitcher />
      </LanguageSwitcherContainer>
      <Container>
        <form onSubmit={handleFormSubmitted}>
          <Block>
            <h2>{t('authentication:login.title')}</h2>
            {error && <ErrorBlock accent>{error}</ErrorBlock>}
            {!!authError && (
              <ErrorBlock accent>{t('authentication:login.error')}</ErrorBlock>
            )}
            <TextField
              name="email"
              label={t('authentication:login.mail-address')}
              onChange={(event) => setEmail(event.target.value)}
              disabled={loading}
              fullWidth
            ></TextField>
            <TextField
              type="password"
              name="password"
              label={t('authentication:login.password')}
              onChange={(event) => setPassword(event.target.value)}
              disabled={loading}
              fullWidth
            ></TextField>
            <LoadingButton
              type="submit"
              loading={loading}
              color="primary"
              disabled={loading}
            >
              {t('authentication:login.submit')}
            </LoadingButton>
            <SideActionsContainer>
              <Link to="/authentication/register">
                {t('authentication:register.title')}
              </Link>
              <Divider orientation="vertical" flexItem />
              <Link to={`/authentication/recover/${email}`}>
                {t('authentication:recover.title')}
              </Link>
            </SideActionsContainer>
          </Block>
        </form>
      </Container>
    </>
  );
};

export default Login;
