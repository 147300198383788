import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import {
  Indicator,
  Indicator2Values,
  Model,
  Result,
  ScoreCard,
} from '@gameonly/core';
import { LinearProgress } from '@mui/material';
import BaseBlock from '../ui/Block';
import Button from '../ui/Button';

import Indicator2ValuesComponent from './Indicator2ValuesComponent';
import IndicatorComponent from './IndicatorComponent';
import ScoreCardComponent from './ScoreCardComponent';

import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Co2Icon } from '../ui/icons/categories/co2.svg';
import { ReactComponent as CoalIcon } from '../ui/icons/categories/coal.svg';
import { ReactComponent as EnergyIcon } from '../ui/icons/categories/energy.svg';
import { ReactComponent as WaterIcon } from '../ui/icons/categories/water.svg';

const Block = styled(BaseBlock)`
  background-color: var(--results-bg);
  height: auto;
  flex: 1;
`;

interface ResultsSimpleProps {
  results?: Result[];
  model: Model;
  loading: boolean;
}

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 16px 0;
`;

const ResultsSimple = ({ results, model, loading }: ResultsSimpleProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['project']);
  return (
    <Block>
      <p className="h4b">{t('project:results-block.title')}</p>
      <ResultsContainer>
        {(results || [])
          .filter((result) => result.scope === 'simple')
          .map((result, index) => {
            return (
              <ResultDispatch
                result={result}
                loading={loading}
                key={index}
                model={model}
              />
            );
          })}
      </ResultsContainer>
      <Button
        variant="contained"
        onClick={() => navigate(`./results`)}
        fullWidth
      >
        {t('project:results-block.full-results')}
      </Button>
    </Block>
  );
};

interface ResultDispatchProps {
  result: Result;
  loading: boolean;
  model: Model;
}

//IT IS NEEDED TO DISPATCH ASAP AS TYPE OF RESULTS DOESN'T HAVE THE SAME PROPERTIES
const ResultDispatch = ({ result, loading, model }: ResultDispatchProps) => {
  switch (result.type) {
    case 'indicator':
      return (
        <ResultSection result={result} loading={loading}>
          <IndicatorComponent result={result} model={model} />
        </ResultSection>
      );
    case 'indicator2Values':
      return (
        <ResultSection result={result} loading={loading}>
          <Indicator2ValuesComponent result={result} model={model} />
        </ResultSection>
      );
    case 'scoreCard':
      return (
        <ResultSection result={result} loading={loading}>
          <ScoreCardComponent result={result}  model={model}/>
        </ResultSection>
      );
  }
  return null;
};

const ResultSectionContainer = styled.div`
  background-color: white;
  width: 100%;
  position: relative;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  @media screen and (max-height: 800px) {
    padding: 8px;
  }
  @media screen and (max-height: 600px) {
    padding: 6px;
  }
`;

interface ResultSectionProps {
  result: Indicator | ScoreCard | Indicator2Values;
  children: JSX.Element | JSX.Element[];
  loading: boolean;
}

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  @media screen and (max-height: 700px) {
    width: 30px;
    height: 30px;
  }
  @media screen and (max-height: 600px) {
    width: 20px;
    height: 20px;
  }
`;

export const scopeIcons: { [key: string]: ReactElement } = {
  co2: <Co2Icon />,
  ressources: <CoalIcon />,
  energie: <EnergyIcon />,
  eau: <WaterIcon />,
  grilles: <CalendarViewMonthIcon />,
};

const ResultSection = ({ result, loading, children }: ResultSectionProps) => {
  return (
    <ResultSectionContainer key={result.title}>
      <LoadingIndicator loading={loading} />
      {children}
    </ResultSectionContainer>
  );
};

export const TitleScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
  p {
    font-size: 15px;
  }
`;

const LoadingIndicatorContainer = styled.div`
  height: 4px;
  opacity: 0;
  transition: opacity 250ms;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &.loading {
    opacity: 0.4;
  }
`;

const LoadingIndicator = ({ loading }: { loading: boolean }) => (
  <LoadingIndicatorContainer className={loading ? 'loading' : ''}>
    <LinearProgress />
  </LoadingIndicatorContainer>
);

export default ResultsSimple;
