import { Report } from '@gameonly/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { authenticatedFetch } from '../../authentication/authenticatedFetch';

const getReport = async (reportId?: string): Promise<Report | undefined> => {
  if (!reportId) return undefined;
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_API_URL}/reports/${reportId}`,
  );
  if (!response.ok) return undefined;
  return await response.json();
};

const renameReport = async ({
  reportId,
  newName,
}: {
  reportId: string;
  newName?: string;
}) => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_API_URL}/reports/${reportId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({ name: newName }),
    },
  );
  if (response.status < 200 || response.status >= 300) {
    throw await response.json();
  }
  return await response.json();
};

const useReport = (reportId: string | undefined) => {
  const queryClient = useQueryClient();

  const reportQuery = useQuery(
    ['report', reportId],
    () => getReport(reportId),
    {
      useErrorBoundary: true,
    },
  );

  const renameReportMutation = useMutation(
    (newName?: string) => {
      if (!reportId) {
        throw new Error('No reportId provided');
      }
      return renameReport({ reportId, newName });
    },
    {
      onMutate: async (newName) => {
        await queryClient.cancelQueries(['report', reportId]);
        const previousReport = queryClient.getQueryData(['report', reportId]);
        queryClient.setQueryData(['report', reportId], (old: any) => ({
          ...old,
          name: newName,
        }));
        return { previousReport };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['report', reportId]);
      },
      onSettled: (updatedProject) => {
      },
    },
  );

  return {
    report: reportQuery.data,
    rename: renameReportMutation.mutateAsync,
    loading: reportQuery.isLoading,
    error: reportQuery.error,
  };
};

export default useReport;
