import { useNavigate, useParams } from 'react-router-dom';

import { Model, Nav, Result, Toggle } from '@gameonly/core';

import { TitleBlock } from '../ui/Block';
import { PageContainer } from '../ui/Container';
import { Abacus } from './ScoreCardComponent';

import { useProject } from '../project/context/useProject';

import styled from '@emotion/styled';
import Bar1DComponent, { Bar1DLegend } from './Bar1DComponent';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarSingle1DComponent, {
  BarSingle1DLegend,
} from './BarSingle1DComponent';
import BarStacked1DComponent from './BarStacked1DComponent';
import BarStacked2DComponent from './BarStacked2DComponent';
import GlobalScoreComponent from './GlobalScore';
import NavComponent from './Nav';
import Pie1DComponent, { Pie1DLegend } from './Pie1DComponent';
import ResultGraph from './ResultGraph';
import SectionTitle, { SectionSubtitle } from './SectionTitle';
import Table from './Table';
import TextBlock from './TextBlock';
import TreemapComponent from './TreemapComponent';
import ToggleComponent from './ToggleComponent';

const ResultsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  border-radius: 8px;
`;

const CategoryContainer = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 8px;
`;

const getResultsByCategories = (results: Result[]) => {
  let resultsByCategories: Result[][] = [];
  let categoryResults: Result[] = [];
  results.forEach((result) => {
    if (result.type === 'title' && categoryResults.length > 0) {
      resultsByCategories.push(categoryResults);
      categoryResults = [result];
    } else {
      categoryResults.push(result);
    }
  });
  resultsByCategories.push(categoryResults);
  return resultsByCategories;
};

const getResultsFiltered = (result: Result, filters: string[] | undefined) => {
  return (
    !filters ||
    !('filter' in result) ||
    (result.filter?.length === 0) ||
    filters.includes(result.filter as string)
  );
};

const getResultsToDisplay = (
  results: Result[],
  filters: string[] | undefined,
) => {
  return results
    .filter(
      (result) =>
        result.scope === 'complete' &&
        result.type !== 'nav' &&
        result.type !== 'toggle',
    )
    .filter((result) => getResultsFiltered(result, filters));
};

const getNav = (results: Result[], filters: string[] | undefined) => {
  return results
    .filter((result) => getResultsFiltered(result, filters))
    .find((result) => result.type === 'nav') as Nav;
};

const Results = () => {
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const navigate = useNavigate();
  const { t } = useTranslation(['project']);
  const [toggle, setToggle] = useState<Toggle | undefined>(undefined);
  const [isToggleSet, setIsToggleSet] = useState(false);

  useEffect(() => {
    const toggleTemp = project?.results.find(
      (result) => result.type === 'toggle',
    ) as Toggle | undefined;
    if (toggleTemp) setToggle(toggleTemp);
    setIsToggleSet(true);
  }, [project]);


  if (!project || !isToggleSet) {
    return null;
  }

  const filters = toggle ? [toggle.selected] : undefined;
  const resultsToDisplay = getResultsToDisplay(project.results, filters);
  const nav = getNav(project.results, filters);
  const resultsByCategories = getResultsByCategories(resultsToDisplay);

  return (
    <PageContainer noGap>
      <TitleBlock
        background="var(--results-bg)"
        style={{ marginBottom: '32px' }}
      >
        {project.name && (
          <h3>
            {project.name} - {t('project:results-page.title')}
          </h3>
        )}
        {!project.name && <h3>{t('project:results-page.title')}</h3>}
        <p>{t('project:results-page.subtitle')}</p>
      </TitleBlock>

      <ResultsContainer>
        <HeaderContainer>
          {nav && <NavComponent result={nav} model={project?.model} />}

          {toggle && <ToggleComponent toggle={toggle} setToggle={setToggle} modelType={project.model.type}/>}
        </HeaderContainer>

        {resultsByCategories.map((results: Result[]) => (
          <CategoryContainer>
            {results.map((result: Result, index: number) => (
              <ResultDispatch
                result={result}
                key={index}
                model={project?.model}
              />
            ))}
          </CategoryContainer>
        ))}
      </ResultsContainer>
    </PageContainer>
  );
};

export const ResultDispatch = ({
  result,
  dashboard,
  model,
}: {
  result: Result;
  dashboard?: boolean;
  model?: Model;
}) => {
  const { t, i18n } = useTranslation(['common', 'project', 'model']);
  switch (result.type) {
    case 'nav':
      return <NavComponent result={result} model={model} />;
    case 'title':
      return <SectionTitle result={result} model={model} />;
    case 'subtitle':
      return <SectionSubtitle result={result} model={model} />;
    case 'text':
      return <TextBlock result={result} model={model} />;
    case 'globalScore':
      const text = i18n.language === 'fr' ? result.text_fr : result.text_en;
      return (
        <GlobalScoreComponent
          title={result.title!}
          subtitle={result.subtitle}
          uncertainty={result.uncertainty}
          score={result.score}
          code={result.code}
          dashboard={dashboard}
          model={model}
        >
          <p className="hxr">{text || result.text}</p>
        </GlobalScoreComponent>
      );
    case 'scoreCard':
      return (
        <GlobalScoreComponent
          title={result.title!}
          score={result.score}
          model={model}
        >
          {result.display! ? (
            <Abacus result={result} visible={result.display!} />
          ) : (
            <p>{t('project:results-page.result-empty')}</p>
          )}
        </GlobalScoreComponent>
      );
    case 'treemap':
      return (
        <ResultGraph
          result={result}
          model={model}
          graph={<TreemapComponent result={result} />}
        />
      );
    case 'pie1D':
      if (dashboard) {
        return <Pie1DComponent result={result} model={model} />;
      }
      return (
        <ResultGraph
          result={result}
          model={model}
          graph={<Pie1DComponent result={result} model={model} />}
          legend={<Pie1DLegend result={result} model={model} />}
        />
      );
    case 'bar1D':
      return (
        <ResultGraph
          result={result}
          model={model}
          graph={<Bar1DComponent result={result} />}
          legend={<Bar1DLegend result={result} />}
        />
      );
    case 'barSingle1D': {
      const fullWidth = (result?.data?.data.length || 0) > 3;
      return (
        <ResultGraph
          result={result}
          model={model}
          fullWidth={fullWidth}
          graph={<BarSingle1DComponent result={result} model={model} />}
          legend={<BarSingle1DLegend result={result} model={model} />}
        />
      );
    }
    case 'barStacked1D': {
      const fullWidth = (result?.data?.data?.length || 0) > 3;
      return (
        <ResultGraph
          result={result}
          model={model}
          fullWidth={fullWidth}
          graph={<BarStacked1DComponent result={result} />}
        />
      );
    }
    case 'barStacked2D':
      return (
        <ResultGraph
          result={result}
          model={model}
          graph={<BarStacked2DComponent result={result} />}
        />
      );

    case 'table':
      return <Table result={result} model={model} />;
  }
  return null;
};

export default Results;
