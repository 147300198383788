import styled from '@emotion/styled';
import { Report } from '@gameonly/core';
import React, { MouseEvent, MouseEventHandler } from 'react';

import ButtonWithConfirm from '../ui/ButtonWithConfirm';

import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import IconButton from '@mui/material/IconButton';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useTranslation } from 'react-i18next';
import BaseBlock from '../ui/Block';
import keyify from '../project/parameters/keyify';

interface ReportCardProps {
  report: Report;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  onDelete?: (event: any) => Promise<Partial<Report>>;
}

const Block = styled(BaseBlock)`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid black;
  height: unset;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: relative;
  height: 150px;
  min-width: 185px;
  .hzr {
    font-size: 10px;
  }
  svg {
    transition: fill 0.2s ease-in-out;
  }
  > div:last-of-type {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    > div {
      flex: 1;
    }
  }
  &:hover {
    border: 2px solid var(--projectCard-borderColor);
    .h6r {
      font-weight: bold;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  text-align: center;
`;

const ProjectCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const ReportCard = ({ report, onClick, onDelete }: ReportCardProps) => {
  const { t } = useTranslation(['annual-reports', 'model']);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const countProjectsByType = report.projects.reduce((acc, project) => {
    const translatedModelName = t(`model:${keyify(project.model.name)}.name`);
    return {
      ...acc,
      [translatedModelName]: (acc[keyify(project.model.name)] || 0) + 1,
    };
  }, {} as { [key: string]: number });

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleDelete = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDelete && (await onDelete(event));
  };

  return (
    <Block onClick={onClick}>
      <IconButton
        sx={{ position: 'absolute', top: '6px', right: '6px', padding: 0 }}
        onClick={handleOpenMenu}
      >
        <MoreHorizOutlinedIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        <MenuItem>
          <ButtonWithConfirm
            onConfirm={handleDelete}
            modalTitle={t('annual-reports:card.delete.title')}
            modalContent={t('annual-reports:card.delete.content')}
          >
            {t('annual-reports:card.delete.button')}
          </ButtonWithConfirm>
        </MenuItem>
      </Menu>
      <div />
      <TitleContainer>
        <p className="h6r" style={{ marginBottom: '10px' }}>
          {report.name}
        </p>
      </TitleContainer>
      <div>
        <p className="hzr">
          {t('annual-reports:card.createdAt', {date: new Date(report.createdAt).toLocaleDateString()})} 
        </p>
      </div>
      <div>
        {Object.keys(countProjectsByType).map((type) => (
          <ProjectCountContainer key={type}>
            <p className="hzb">{countProjectsByType[type]}</p>
            <p className="hzr">{type}</p>
          </ProjectCountContainer>
        ))}
      </div>
    </Block>
  );
};

export default ReportCard;
