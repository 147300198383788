import styled from '@emotion/styled';
import { Model, ParameterInput, Project } from '@gameonly/core';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useProjects from '../../../project/context/useProjects';
import ProjectCard from '../../../project/ProjectCard';
import { sortByDate } from '../../../project/ProjectsUtils';
import {
  BlockContainer,
  BlockContent as BaseBlockContent,
  BlockHeader,
} from '../../ui';
import CreateProjectModal from './CreateProjectModal';
import Empty from './Empty';
import NewProjectButtonDashboard from './NewProjectButtonDashboard';
import keyify from '../../../project/parameters/keyify';

const BlockContent = styled(BaseBlockContent)`
  height: 180px;
`;

const NewProjectBlock = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #000000;
  border-radius: 8px;
  gap: 16px;
  height: 150px;
  min-width: 200px;
  cursor: pointer;

  &:hover {
    border: 1px solid #000000;
    cursor: pointer;
    p {
      text-decoration: underline;
    }
  }
`;

interface ModelBlockProps {
  model: Model;
  sortOrder?: string;
  limit?: number;
  className?: string;
  fullHeight?: boolean;
}

const ModelBlock = ({
  model,
  sortOrder,
  limit,
  className,
  fullHeight,
}: ModelBlockProps) => {
  const { t } = useTranslation(['dashboard', 'model']);
  const { projects, remove, count, duplicate } = useProjects({
    projectType: model.type,
    limit,
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const blockContentRef = useRef<HTMLDivElement>(null);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    if (blockContentRef.current) {
      const blockRect = blockContentRef.current.getBoundingClientRect();
      const lastCardRect = blockContentRef.current
        .querySelector(':scope > div:last-of-type')
        ?.getBoundingClientRect();
      if (!lastCardRect) {
        return;
      }
      setOverflow(
        blockRect.bottom < lastCardRect.bottom ||
          blockRect.right < lastCardRect.right,
      );
    }
  }, [blockContentRef, projects]);

  if (!projects) {
    return <Empty model={model} />;
  }

  const sortedProjects = projects!.sort((a: Project, b: Project) => {
    if (sortOrder === 'date') {
      return sortByDate(a, b);
    }
    return a.completionRate - b.completionRate;
  });

  const handleSave = (values: ParameterInput[], modelType: string) => {
    navigate(`/project?values=${JSON.stringify(values)}&type=${modelType}`);
  };

  const handleDelete = (id: string) => async (event: any) => {
    return await remove(id);
  };

  const handleDuplicate = (id: string) => async (event: any) => {
    return await duplicate(id);
  };

  const handleNewProjectClicked = () => {
    setOpen(true);
  };
  const translationPrefix = `model:${keyify(model.name)}`;

  return (
    <>
      <CreateProjectModal
        open={open}
        setOpen={setOpen}
        onSave={handleSave}
        model={model}
      ></CreateProjectModal>
      <BlockContainer className={className}>
        <BlockHeader>
          <Link to={`/projects/${model.type}`}>
            {t(`${translationPrefix}.name`)} ({count})
          </Link>
          {overflow && (
            <Button
              startIcon={<ReadMoreIcon />}
              color="primary"
              variant="outlined"
              href={`/projects/${model.type}`}
              size="small"
            >
              {t('dashboard:models.block.see-more')}
            </Button>
          )}
        </BlockHeader>
        <BlockContent
          color={model.color}
          ref={blockContentRef}
          className={fullHeight ? 'full-height' : undefined}
        >
          {projects?.length === 0 && (
            <Empty model={model} onNewProject={handleNewProjectClicked} />
          )}
          {projects?.length > 0 && (
            <>
              <NewProjectBlock onClick={handleNewProjectClicked}>
                <NewProjectButtonDashboard model={t(`${translationPrefix}.singularName`)} />
              </NewProjectBlock>
              {sortedProjects?.map((project: Project) => (
                <ProjectCard
                  key={project._id.toString()}
                  project={project}
                  onClick={() => navigate(`/project/${project._id}`)}
                  onDelete={handleDelete(project._id.toString())}
                  onDuplicate={handleDuplicate(project._id.toString())}
                />
              ))}
            </>
          )}
        </BlockContent>
      </BlockContainer>
    </>
  );
};

export default ModelBlock;
