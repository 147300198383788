import styled from '@emotion/styled';
import { Model } from '@gameonly/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GraphTitle } from './ResultGraph';

const GlobalScoreContainer = styled.div`
  width: 100%;
  background-color: white;
  position: relative;
  margin: 16px 0;
  padding: 0 16px;
  > div:last-of-type {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    > p {
      flex: 1;
    }
    > p:first-of-type {
      text-align: center;
      background: var(--bg-score);
      padding: 6px 0;
      border-radius: 24px;
    }
  }
`;

interface GlobalScoreComponentProps {
  title?: string;
  subtitle?: string;
  score?: string;
  children: React.ReactNode;
  dashboard?: boolean;
  code?: string;
  model?: Model;
  uncertainty?: number;
}

const GlobalScoreComponent = ({
  title,
  score,
  subtitle,
  uncertainty,
  children,
  dashboard,
  code,
  model,
}: GlobalScoreComponentProps) => {
  const { t, i18n } = useTranslation(['project', 'model']);
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${code?.toLowerCase()}`;
  if (dashboard) {
    return (
      <div>
        <p className="h5r">{t(`${translationPrefix}.title`)}</p>
        <p className="h3b">{score}</p>
      </div>
    );
  }
  return (
    <GlobalScoreContainer>
      {i18n.exists(`${translationPrefix}.title`) && (
        <GraphTitle
          text={t(`${translationPrefix}.title`)}
          size="full"
          color="default"
        />
      )}
      {i18n.exists(`${translationPrefix}.subtitle`) && (
        <p className="h6r">{t(`${translationPrefix}.subtitle`)}</p>
      )}
      <div>
        <p className="h3b">
          {score} {uncertainty && <> ± {(uncertainty * 100).toFixed(0)}%</>}
        </p>
        {children}
      </div>
    </GlobalScoreContainer>
  );
};

export default GlobalScoreComponent;
