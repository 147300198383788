import styled from '@emotion/styled';
import { Model, Subtitle, Title } from '@gameonly/core';
import { useTranslation } from 'react-i18next';
import { SectorHeaderWrapper } from '../project/Project';
import { IconContainer, scopeIcons } from './ResultsSimple';

const SectionTitleContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  color: black;
  margin-bottom: 16px;
  .result_anchor {
    visibility: hidden;
    position: absolute;
    top: -48px;
  }
`;

const SectionTitle = ({ result, model }: { result: Title; model?: Model }) => {
  const icon = scopeIcons[result.icon!];
  const { t } = useTranslation(['project', 'model']);
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${
    result.code?.toLowerCase()
  }`;
  return (
    <SectionTitleContainer>
      <div id={`results_${result.icon}`} className="result_anchor"></div>
      <IconContainer>{icon}</IconContainer>
      <p className="h4b">{t(`${translationPrefix}.text`)}</p>
    </SectionTitleContainer>
  );
};

const SectionSubtitleContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 16px;
  background-color: white;
  > div {
    margin-bottom: 0 !important;
  }
`;

export const SectionSubtitle = ({
  result,
  model,
}: {
  result: Subtitle;
  model?: Model;
}) => {
  const { t } = useTranslation(['project', 'model']);
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${
    result.code?.toLowerCase()
  }`;
  return (
    <SectionSubtitleContainer>
      <SectorHeaderWrapper
        backgroundColor={'var(--color-accent)'}
        className={`level0`}
        style={{ zIndex: '1' }}
      >
        <p className="h4b">{t(`${translationPrefix}.text`)}</p>
      </SectorHeaderWrapper>
    </SectionSubtitleContainer>
  );
};

export default SectionTitle;
