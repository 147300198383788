import styled from '@emotion/styled';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Paper, Popper, Tooltip } from '@mui/material';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useProject } from './context/useProject';
import keyify from './parameters/keyify';
const Title = styled.div`
  text-align: center;
  font-weight: bold;
  padding-bottom: 24px;
`;

const Text = styled.p`
  margin-bottom: 16px;
`;

const Li = styled.li`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  > div {
    display: flex;
  }
`;

const CompletionDetails = ({ onClose, ...props }: any) => {
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const { i18n, t } = useTranslation(['project', 'model']);

  const handleClick =
    (parameterIndex: number) => (event: MouseEvent<HTMLLIElement>) => {
      const scrollHandle = document.getElementById(
        `parameter-${parameterIndex}`,
      );
      if (!scrollHandle) return;

      const container = scrollHandle.parentElement;
      if (!container) return;

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              container.classList.add('blink');
              setTimeout(() => {
                container.classList.remove('blink');
              }, 1600);
            }, 400);
            observer.unobserve(entry.target);
          }
        });
      });

      observer.observe(container);

      scrollHandle.scrollIntoView({ block: 'center', behavior: 'smooth' });

      onClose();
    };

  const translationPrefix = `model:${keyify(project?.model.name)}.parameter`;

  const parameters: any[] = [
    ...(project?.new || [])?.map((parameter) => ({ ...parameter, new: true })),
    ...(project?.uncompleted ?? []).filter(
      (parameter) => !project?.new?.find((p) => p.index === parameter.index),
    ),
  ].filter(
    (parameter) =>
      !!parameter.id &&
      (i18n.exists(`${translationPrefix}.${keyify(parameter.id)}.name`) ||
        i18n.exists(
          `${translationPrefix}.${keyify(parameter.id)}.possibleValues`,
        )),
  );

  const hasRequiredFields = project?.model.hasRequiredFields;
  const completionRate = project?.completionRate;
  
  const informationText = hasRequiredFields
    ? completionRate === 100
      ? t(`model:with-required-fields.information-text-when-project-complete`)
      : t(`model:with-required-fields.information-text-when-project-uncomplete`)
    : null;

  return (
    <Popper {...props} onClose={onClose}>
      <Paper style={{ padding: '24px', maxWidth: '420px' }}>
        <Title>
          {t('project:completion.title', {
            count: project?.uncompleted?.length,
          })}
        </Title>
        {informationText && <Text className="hzr">{informationText}</Text>}
        <ul style={{ marginLeft: '24px' }}>
          {parameters.slice(0, 8).map((parameter) => (
            <Li
              onClick={handleClick(parameter.index ?? -1)}
              key={parameter.index}
            >
              <div>
                {t(`${translationPrefix}.${keyify(parameter.id)}.name`) ||
                  t(
                    `${translationPrefix}.${keyify(
                      parameter.id,
                    )}.possibleValues`,
                  )
                    .split(',')
                    .join(' / ')}
                {parameter.new && (
                  <Tooltip
                    title={t('project:completion.new-parameter-tooltip')}
                  >
                    <FiberNewIcon color="success" style={{ marginLeft: 8 }} />
                  </Tooltip>
                )}
              </div>
            </Li>
          ))}
          {parameters.length > 8 && <li>...</li>}
        </ul>
      </Paper>
    </Popper>
  );
};

export default CompletionDetails;
