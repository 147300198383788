import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/translations/{{lng}}/{{ns}}`,
      addPath: `${process.env.REACT_APP_API_URL}/translations/{{lng}}/{{ns}}`,
      parsePayload: (
        namespace: string,
        key: string,
        fallbackValue: string,
      ) => ({
        key,
      }),
      reloadInterval: 10000,
    },

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
