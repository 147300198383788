import { Model, Parameter, Value } from '@gameonly/core';
import { Alert, AlertTitle, SelectChangeEvent } from '@mui/material';
import { ChangeEvent, SyntheticEvent, useCallback } from 'react';

import formatDate from 'date-fns/format';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import CheckboxSelect from './CheckboxSelect';
import DateInput from './DateInput';
import Explanation from './Explanation';
import ImportProject from './ImportProject';
import keyify from './keyify';
import NumberInput from './NumberInput';
import RadioSelect, {MeasureTypeSelect} from './RadioSelect';
import Select from './Select';
import Slider from './Slider';
import TextField from './TextField';

const fixDateType = (value: string) => {
  if (String(value) === value) {
    if (Date.parse(value) > 0) {
      return new Date(value);
    }
  }
  return value;
};

type MeasureTypeValues = {
  [key: string]: string;
};

const getOrderedMeasureTypeValues = (measureTypeValues: MeasureTypeValues) => {
  return Object.keys(measureTypeValues)
    .sort((a, b) => b.localeCompare(a, undefined, { numeric: true }))
    .map((key) => measureTypeValues[key]);
};

const checkDate = (value: any) => {
  if (value) {
    if (value instanceof Date) return formatDate(value, 'yyyy-MM-dd');
    if (!isNaN(value))
      return formatDate(
        new Date(Math.round((value - 25569) * 86400 * 1000)),
        'yyyy-MM-dd',
      );
  }
  return null;
};

const checkPossibleValues = (parameter: Parameter) => {
  if (
    !parameter.possibleValues ||
    typeof parameter.possibleValues === 'undefined' ||
    parameter.possibleValues.length === 0
  ) {
    throw new Error('Possible values vide');
  }
  if (!Array.isArray(parameter.possibleValues)) {
    throw new Error("Possible values n'est pas une liste de valeur");
  }
  const dedupedPossibleValues = Array.from(new Set(parameter.possibleValues));
  if (parameter.possibleValues.length !== dedupedPossibleValues.length) {
    throw new Error(
      `Duplicates in possible values : ${parameter.possibleValues.join(', ')}`,
    );
  }
  return null;
};

interface ParameterFactoryProps {
  autofocus: boolean;
  parameter: Parameter;
  onUpdateParameter: (index: number, value: Value) => void;
  debounced?: boolean;
  model: Model;
}

const ParameterFactory = ({
  autofocus,
  parameter,
  onUpdateParameter,
  debounced = false,
  model,
}: ParameterFactoryProps) => {
  const { t, i18n } = useTranslation(['model']);
  const translationPrefix = `model:${keyify(model.name)}.parameter.${keyify(
    parameter.id || '',
  )}`;
  if (parameter.configError) {
    // throw new Error(parameter.error);
  }

  const focusUsernameInputField = (input: HTMLInputElement) => {
    if (input && autofocus) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };

  const handleRadioSelectChanged = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    const translatedPossibleValues = t(`${translationPrefix}.possiblevalues`)
      .split(',')
      .map((v: string) => v.trim());
    const index = translatedPossibleValues.indexOf(value);
    onUpdateParameter(parameter.index, parameter.possibleValues?.[index]);
  };

  const handleMeasureTypeChanged = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    const measureTypeValues = t(`project:parameter.measureType`, {
      returnObjects: true,
    }) as MeasureTypeValues;
    const translatedPossibleValues =
      getOrderedMeasureTypeValues(measureTypeValues);
    const index = translatedPossibleValues.indexOf(value);
    onUpdateParameter(parameter.index, translatedPossibleValues.length - index);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleNumberChanged = useCallback(
    debounce(
      (event: SyntheticEvent, value: number | undefined) => {
        handleNumberChanged(event, value);
      },
      500,
      { trailing: true, maxWait: 3000 },
    ),
    [],
  );
  const handleNumberChanged = (
    event: SyntheticEvent,
    value: number | undefined,
  ) => {
    if (value) {
      if (parameter.min && value < parameter.min) {
        value = parameter.min;
      }
      if (parameter.max && value > parameter.max) {
        value = parameter.max;
      }
    }
    onUpdateParameter(parameter.index, value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleTextChanged = useCallback(
    debounce(
      (event: ChangeEvent<HTMLInputElement>) => {
        handleTextChanged(event);
      },
      500,
      { trailing: true, maxWait: 3000 },
    ),
    [],
  );
  const handleTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const fixedValue =
      parameter.type === 'date'
        ? fixDateType(event.target.value)
        : event.target.value;
    onUpdateParameter(parameter.index, fixedValue);
  };

  const handleSelectChanged = (event: SelectChangeEvent<unknown>) => {
    const translatedPossibleValues = t(`${translationPrefix}.possiblevalues`)
      .split(',')
      .map((v: string) => v.trim());
    const index = translatedPossibleValues.indexOf(
      event.target.value as string,
    );
    onUpdateParameter(parameter.index, parameter.possibleValues?.[index]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSliderChanged = useCallback(
    debounce(
      (event: Event, value: number | number[], activeThumb: number) => {
        handleSliderChanged(event, value, activeThumb);
      },
      500,
      { trailing: true, maxWait: 3000 },
    ),
    [],
  );
  const handleSliderChanged = (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => {
    if (Array.isArray(value)) {
      return;
    }
    onUpdateParameter(parameter.index, value);
  };

  const handleCheckboxGroupChanged = (
    event: ChangeEvent<HTMLInputElement>,
    values: string[],
  ) => {
    onUpdateParameter(parameter.index, values);
  };

  switch (parameter.type?.trim()) {
    case 'radio': {
      checkPossibleValues(parameter);
      const translatedPossibleValues = t(`${translationPrefix}.possiblevalues`)
        .split(',')
        .map((v: string) => v.trim());
      const valueIndex = parameter.possibleValues?.indexOf(
        parameter.value as string,
      );
      let value = parameter.initialValue;
      if (valueIndex !== undefined && valueIndex !== null && valueIndex >= 0) {
        value = translatedPossibleValues[valueIndex] || parameter.initialValue;
      }
      return (
        <RadioSelect
          possibleValues={translatedPossibleValues || []}
          onChange={handleRadioSelectChanged}
          defaultValue={value}
        />
      );
    }
    case 'measureType': {
      const measureTypeValues = t(`project:parameter.measureType`, {
        returnObjects: true,
      }) as MeasureTypeValues;
      const translatedPossibleValues =
        getOrderedMeasureTypeValues(measureTypeValues);
      const valueIndex = translatedPossibleValues.length - Number(parameter.value); // as array is reversed : ["5 - mesure directe", "4 - xxx", "3 - xxx"]
      let value = parameter.initialValue;
      if (
        valueIndex !== undefined &&
        valueIndex !== null &&
        valueIndex >= 0 &&
        valueIndex < translatedPossibleValues.length
      ) {
        value = translatedPossibleValues[valueIndex] || parameter.initialValue;
      }
      return (
        <MeasureTypeSelect
          possibleValues={translatedPossibleValues || []}
          onChange={handleMeasureTypeChanged}
          defaultValue={value}
        />
      );
    }
    case 'number': {
      let defaultValue = null;
      if (typeof parameter.initialValue === 'number') {
        defaultValue = parameter.initialValue;
      }
      if (
        typeof parameter.value === 'number' ||
        (typeof parameter.value === 'string' && parameter.value.length > 0)
      ) {
        defaultValue = parameter.value;
      }
      return (
        <NumberInput
          onChange={
            debounced ? debouncedHandleNumberChanged : handleNumberChanged
          }
          unit={
            i18n.exists(`${translationPrefix}.unit`)
              ? t(`${translationPrefix}.unit`)
              : parameter.unit
          }
          defaultValue={defaultValue}
          inputProps={{
            min: parameter.min,
            max: parameter.max,
            step: parameter.step,
          }}
        />
      );
    }
    case 'list': {
      checkPossibleValues(parameter);
      const translatedPossibleValues = t(`${translationPrefix}.possiblevalues`)
        .split(',')
        .map((v: string) => v.trim());
      const valueIndex = parameter.possibleValues?.indexOf(
        (parameter.value as string[])[0],
      );
      let value = parameter.initialValue;
      if (valueIndex !== undefined && valueIndex !== null && valueIndex >= 0) {
        value = translatedPossibleValues[valueIndex] || parameter.initialValue;
      }
      return (
        <Select
          possibleValues={translatedPossibleValues || []}
          onChange={handleSelectChanged}
          defaultValue={value}
        />
      );
    }
    case 'date': {
      return (
        <DateInput
          defaultValue={
            checkDate(parameter.value) || checkDate(parameter.initialValue)
          }
          onChange={debounced ? debouncedHandleTextChanged : handleTextChanged}
        />
      );
    }
    case 'text': {
      return (
        <TextField
          inputRef={focusUsernameInputField}
          key={parameter.value?.toString()}
          defaultValue={parameter.value || parameter.initialValue}
          onChange={debounced ? debouncedHandleTextChanged : handleTextChanged}
          multiline={parameter.step ? parameter.step > 1 : false}
          rows={parameter.step}
        />
      );
    }
    case 'explanation': {
      return <Explanation value={t(`${translationPrefix}.value`) || ''} />;
    }
    case 'import_project': {
      return (
        <ImportProject
          projectTypes={parameter.possibleValues || []}
          title={t(`${translationPrefix}.name`)}
          description={t(`${translationPrefix}.description`)}
        />
      );
    }
    case 'cursor': {
      let defaultValue = 0;
      if (parameter.min) {
        defaultValue = parameter.min;
      }
      if (parameter.initialValue) {
        defaultValue = parseInt(parameter.initialValue.toString());
      }
      if (parameter.value) {
        defaultValue = parseInt(parameter.value.toString());
      }

      return (
        <Slider
          min={parameter.min}
          max={parameter.max}
          step={parameter.step}
          onChange={
            debounced ? debouncedHandleSliderChanged : handleSliderChanged
          }
          defaultValue={defaultValue}
          unit={
            i18n.exists(`${translationPrefix}.unit`)
              ? t(`${translationPrefix}.unit`)
              : parameter.unit
          }
        />
      );
    }
    case 'checkbox': {
      checkPossibleValues(parameter);

      const translatedPossibleValues = t(`${translationPrefix}.possiblevalues`)
        .split(',')
        .map((v: string) => v.trim());

      let defaultValue: string[] = [];
      if (parameter.initialValue) {
        if (Array.isArray(parameter.initialValue)) {
          defaultValue = parameter.initialValue
            .map((v: string) => v.trim())
            .map((v: string) => {
              const valueIndex = parameter.possibleValues?.indexOf(v);
              if (
                valueIndex !== undefined &&
                valueIndex !== null &&
                valueIndex >= 0
              ) {
                return translatedPossibleValues[valueIndex] || v;
              }
              return v;
            });
        } else {
          const valueIndex = parameter.possibleValues?.indexOf(
            parameter.initialValue.toString(),
          );
          if (
            valueIndex !== undefined &&
            valueIndex !== null &&
            valueIndex >= 0
          ) {
            defaultValue = [translatedPossibleValues[valueIndex]];
          } else {
            defaultValue = [parameter.initialValue.toString()];
          }
        }
      }

      if (parameter.value) {
        if (Array.isArray(parameter.value)) {
          defaultValue = parameter.value
            .map((v: string) => v.trim())
            .map((v: string) => {
              const valueIndex = parameter.possibleValues?.indexOf(v);
              if (
                valueIndex !== undefined &&
                valueIndex !== null &&
                valueIndex >= 0
              ) {
                return translatedPossibleValues[valueIndex] || v;
              }
              return v;
            });
        } else {
          const valueIndex = parameter.possibleValues?.indexOf(
            parameter.value.toString(),
          );
          if (
            valueIndex !== undefined &&
            valueIndex !== null &&
            valueIndex >= 0
          ) {
            defaultValue = [translatedPossibleValues[valueIndex]];
          } else {
            defaultValue = [parameter.value.toString()];
          }
        }
      }

      return (
        <CheckboxSelect
          possibleValues={translatedPossibleValues || []}
          onChange={handleCheckboxGroupChanged}
          defaultValue={defaultValue}
        />
      );
    }
  }

  return <div>{parameter.type}</div>;
};

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Alert severity="error">
      <AlertTitle>Problème de paramétrage</AlertTitle>
      {error.message}
    </Alert>
  );
};

const SafeParameterFactory = (props: ParameterFactoryProps) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ParameterFactory {...props} />
  </ErrorBoundary>
);

export default SafeParameterFactory;
