import { Action, Impact } from '@gameonly/core';
import styled from '@emotion/styled';
import { MouseEvent, ReactElement, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import BaseBlock from '../../ui/Block';
import { ReactComponent as BiodivIcon } from '../../ui/icons/biodiv.svg';
import { ReactComponent as Co2Icon } from '../../ui/icons/co2.svg';
import { ReactComponent as CostIcon } from '../../ui/icons/cost.svg';
import { ReactComponent as ResourcesIcon } from '../../ui/icons/ressources.svg';
import { ReactComponent as TimeframeIcon } from '../../ui/icons/timeframe.svg';

const ImpactsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
  flex-grow: 0;
  width: 100%;
`;

const Title = styled.h3`
  margin-bottom: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-grow: 1;
`;

const Block = styled(BaseBlock.withComponent('a'))`
  cursor: pointer;
  transition: border 0.5s ease-in-out;
  border: 1px solid white;
  padding: 16px 16px 24px;
  h3 {
    transition: text-decoration-color 0.5s ease-in-out;
    text-decoration: underline;
    text-decoration-color: transparent;
  }
  &:hover {
    border: 1px solid var(--yellow);

    h3 {
      text-decoration-color: #060606;
    }
  }
`;

const ImpactContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const IconContainer = styled.div`
  height: 40px;
  display: flex;
`;

const scopeIcons: { [key: string]: ReactElement } = {
  biodiv: <BiodivIcon />,
  ressources: <ResourcesIcon />,
  co2: <Co2Icon />,
  none: <Co2Icon />,
};

const numberFormatter = Intl.NumberFormat(undefined, {
  signDisplay: 'exceptZero',
});

const PercentageContainer = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;

  > span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
`;

const ImpactComponent = ({ impact }: { impact: Impact }) => {
  const icon = scopeIcons[(impact.scope || 'none').trim()] ?? (
    <img src="/action.png" alt="action" />
  );
  const percentage = impact.absolutePercentage ?? 0;
  const bubbleSize =
    percentage > 75
      ? 'big'
      : percentage > 50
      ? 'medium'
      : percentage > 25
      ? 'small'
      : 'tiny';
  return (
    <BaseBlock
      outlined
      style={{ margin: '8px 0', height: '125px', flexGrow: 1 }}
    >
      <ImpactContainer>
        <div>
          <IconContainer>{icon}</IconContainer>
        </div>
        <Bubble
          size={bubbleSize}
          value={impact.value}
          unit={impact.unit}
        ></Bubble>
      </ImpactContainer>
      {impact.scope === 'co2' && percentage !== null && (
        <PercentageContainer>
          {percentage}% <span>de réduction</span>
        </PercentageContainer>
      )}
    </BaseBlock>
  );
};

const SectorContainer = styled.div`
  margin-bottom: 8px;
  flex-grow: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
`;

interface ActionProps {
  action: Action;
}

const ChipsContainer = styled.div`
  display: flex;
  flex-grow: 0;
  width: 100%;
  justify-content: center;
  gap: 16px;
`;

interface ChipData {
  icon: ReactElement;
  title: string;
  colors: {
    [key: string]: string;
  };
}

const chipDatas: { [key: string]: ChipData } = {
  cost: {
    icon: <CostIcon />,
    title: 'Coût',
    colors: {
      Négatif: '#8746AE',
      Nul: '#B790CE',
      Faible: '#DBC7E7',
      Moyen: '#F3ECF7',
    },
  },
  duration: {
    icon: <TimeframeIcon />,
    title: '',
    colors: {
      Immédiat: '#FED169',
      'Moyen terme': '#FEE3A5',
      'Long terme': '#FFF1D2',
    },
  },
};

const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border: 0;
  border-radius: 16px;
  padding: 2px 12px 2px 10px;
`;

// https://stackoverflow.com/a/72595895/1665540
const contrastColor = (backgroundColor: string) => {
  try {
    return ['black', 'white'][
      ~~(
        [1, 3, 5]
          .map((p) => parseInt(backgroundColor.substr(p, 2), 16))
          .reduce((r, v, i) => [0.299, 0.587, 0.114][i] * v + r, 0) < 128
      )
    ];
  } catch (e) {
    return 'black';
  }
};

const ChipCompoment = ({
  label,
  chipData,
}: {
  label: string;
  chipData: ChipData;
}) => {
  const color = chipData.colors[label as keyof typeof chipData.colors];
  const textColor = contrastColor(color);
  return (
    <ChipContainer
      style={{ backgroundColor: color, color: textColor, stroke: textColor }}
    >
      {chipData.icon}
      <p className="hzr">
        {chipData.title ? `${chipData.title} ${label.toLowerCase()}` : label}
      </p>
    </ChipContainer>
  );
};

const DetailedActionCard = ({ action }: ActionProps) => {
  const [noLinkYetModalOpen, setNoLinkYetModalOpen] = useState(false);
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (!action.link) {
      event.preventDefault();
      event.stopPropagation();
      setNoLinkYetModalOpen(true);
    }
  };

  const handleClose = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setNoLinkYetModalOpen(false);
  };

  return (
    <Block
      href={action.link}
      onClick={handleClick}
      target="_blank"
      style={{
        maxWidth: 335,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
      }}
    >
      <SectorContainer className="hzr">{action.sector}</SectorContainer>
      <Title className="hxc" style={{ width: '100%', textOverflow: 'clip' }}>
        {action.title}
      </Title>
      <ImpactsContainer>
        {action.impacts.map((impact, index) => {
          return <ImpactComponent impact={impact} key={index} />;
        })}
      </ImpactsContainer>
      <ChipsContainer>
        {Object.entries(chipDatas).map(([chipKey, chipData]) => (
          <ChipCompoment
            key={chipKey}
            label={action[chipKey as keyof typeof action] as string}
            chipData={chipData}
          />
        ))}
      </ChipsContainer>

      <Dialog open={noLinkYetModalOpen} onClose={handleClose}>
        <DialogTitle>A venir</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              fontFamily: "'Montserrat', sans-serif",
              margin: '16px 0 32px',
            }}
          >
            Ici vous pourrez bientôt consulter des fiches détaillées sur les
            actions proposées.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Block>
  );
};

const BubbleShape = styled.div`
  border-radius: 50%;
  font-family: 'Montserrat';
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #060606;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  position: relative;
  z-index: 1;
  &.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    overflow: visible;
    color: #b9dadd;

    &.big {
      color: #b9dadd;
    }
  }

  > span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.06em;
  }

  &.tiny {
    background-color: #dceeef;
    width: 48px;
    height: 48px;
  }

  &.small {
    width: 56px;
    height: 56px;
    background: #b9dadd;
  }

  &.medium {
    width: 64px;
    height: 64px;
    background: var(--results-bg);
  }

  &.big {
    width: 72px;
    height: 72px;
    background: #16838f;
    color: #ffffff;
  }
`;

const BubbleContainer = styled.div`
  position: relative;
`;

const Bubble = ({
  size,
  value,
  unit,
}: {
  size: 'tiny' | 'small' | 'medium' | 'big';
  value: number;
  unit: string;
}) => {
  return (
    <BubbleContainer>
      <BubbleShape className={`${size} backdrop`}>
        {numberFormatter.format(value)}
        <span>{unit}</span>
      </BubbleShape>
      <BubbleShape className={size}>
        {numberFormatter.format(value)}
        <span>{unit}</span>
      </BubbleShape>
    </BubbleContainer>
  );
};

export default DetailedActionCard;
