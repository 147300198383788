import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import BaseBlock from '../../ui/Block';
import Button from '../../ui/Button';
import Markdown from '../../ui/Markdown';
import useProjects from '../context/useProjects';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import LoadingButton from '../../ui/LoadingButton';
import { getEtp, getYear, useProject } from '../context/useProject';

const Block = styled(BaseBlock)`
  text-align: center;

  > div {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
`;

const Description = styled.div`
  margin-bottom: 1rem;
`;

interface ImportProjectModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  projectTypes: string[];
}

interface SelectedProject {
  id?: string | null;
  label?: string | null;
  year?: number | null;
  baseYear?: number | null;
  etp?: number | null;
  baseEtp?: number | null;
}

const defaultSelectedProject = {
  id: null,
  label: null,
  year: null,
  baseYear: undefined,
  etp: null,
  baseEtp: null,
};

const ImportProjectModal = ({
  open,
  setOpen,
  projectTypes,
}: ImportProjectModalProps) => {
  const { projects } = useProjects({
    projectType: projectTypes,
  });

  const { projectId } = useParams();
  const { importProject, loading } = useProject(projectId);

  const { enqueueSnackbar } = useSnackbar();

  const [selectedProjects, setSelectedProjects] = useState<SelectedProject[]>([
    defaultSelectedProject,
  ]);

  useEffect(() => {
    if (selectedProjects.every((project) => !!project.id)) {
      setSelectedProjects([
        ...selectedProjects,
        {
          id: undefined,
          year: undefined,
          baseYear: undefined,
          etp: undefined,
          baseEtp: undefined,
        },
      ]);
    }
  }, [selectedProjects]);

  const handleProjectChanged =
    (selectedProject: SelectedProject, index: number) =>
    (
      event: SyntheticEvent<Element, Event>,
      value: {
        label: string | undefined | null;
        value: string;
      } | null,
    ) => {
      if (value) {
        const foundProject = projects?.find(
          (project) => project._id.toString() === value.value,
        );

        if (!foundProject) {
          return;
        }

        const baseEtp = getEtp(foundProject);

        setSelectedProjects([
          ...selectedProjects.slice(0, index),
          {
            id: value.value,
            label: value.label,
            year: selectedProject.year || getYear(foundProject),
            baseYear: getYear(foundProject),
            etp: selectedProject.etp,
            baseEtp,
          },
          ...selectedProjects.slice(index + 1),
        ]);
      } else {
        setSelectedProjects([
          ...selectedProjects.slice(0, index),
          ...selectedProjects.slice(index + 1),
        ]);
      }
    };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await importProject(selectedProjects);

    enqueueSnackbar('Projets importés avec succès', {
      variant: 'success',
    });

    handleClose();
  };

  const handleClose = () => {
    setSelectedProjects([defaultSelectedProject]);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClick={(event) => {
        event.stopPropagation();
      }}
      onClose={handleClose}
      maxWidth="md"
      sx={{
        maxWidth: 'none',
        width: '100%',
      }}
    >
      <DialogContent
        dividers={true}
        sx={{
          maxWidth: 'none',
        }}
      >
        <DialogContentText>
          <form onSubmit={handleSubmit}>
            <p>
              Sélectionnez les locaux concernés par ce rapport et le nombre
              d'ETP affecté
            </p>
            <table style={{ margin: '20px auto' }} cellSpacing={20}>
              <thead>
                <tr>
                  <th>Projet</th>
                  <th>Année</th>
                  <th>ETP</th>
                </tr>
              </thead>
              <tbody>
                {selectedProjects.map((selectedProject, index) => (
                  <tr key={selectedProject.id}>
                    <td>
                      <Autocomplete
                        options={
                          projects?.map((project) => ({
                            label: project.name,
                            value: project._id.toString(),
                          })) || []
                        }
                        sx={{ width: 300 }}
                        value={
                          selectedProject.id
                            ? {
                                label: selectedProject.label,
                                value: selectedProject.id,
                              }
                            : undefined
                        }
                        onChange={handleProjectChanged(selectedProject, index)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sélectionnez un projet..."
                          />
                        )}
                      />
                    </td>
                    <td>
                      <TextField
                        type="number"
                        sx={{ width: 200 }}
                        key={selectedProject.baseYear || undefined}
                        required={!!selectedProject.id}
                        defaultValue={selectedProject.baseYear || null}
                        onChange={(event) => {
                          setSelectedProjects([
                            ...selectedProjects.slice(0, index),
                            {
                              ...selectedProject,
                              year: parseInt(event.target.value),
                            },
                            ...selectedProjects.slice(index + 1),
                          ]);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </td>
                    <td>
                      <TextField
                        type="number"
                        sx={{ width: 200 }}
                        required={!!selectedProject.id}
                        // defaultValue={selectedProject.etp || null}
                        inputProps={{
                          step: 0.1,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!!selectedProject.baseEtp && (
                                <Typography variant="caption">
                                  / {selectedProject.baseEtp}
                                </Typography>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(event) => {
                          setSelectedProjects([
                            ...selectedProjects.slice(0, index),
                            {
                              ...selectedProject,
                              etp: parseInt(event.target.value),
                            },
                            ...selectedProjects.slice(index + 1),
                          ]);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={handleClose}
                color="secondary"
                startIcon={<CloseIcon />}
              >
                Fermer
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                startIcon={<ArrowCircleDownIcon />}
                loading={loading}
                disabled={loading}
              >
                Importer
              </LoadingButton>
            </div>
          </form>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

interface ImportProjectProps {
  title: string;
  description: string;
  projectTypes: string[];
}
const ImportProject = ({
  title,
  description,
  projectTypes,
}: ImportProjectProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <>
      <ImportProjectModal
        open={openModal}
        setOpen={setOpenModal}
        projectTypes={projectTypes}
      />
      <Block accent>
        <Title>{title}</Title>
        <Description>
          <Markdown>{description}</Markdown>
        </Description>
        <Button
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Importer
        </Button>
      </Block>
    </>
  );
};

export default ImportProject;
