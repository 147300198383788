import { Toggle } from '@gameonly/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Paper, ToggleButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  toggle: Toggle;
  setToggle: (toggle: Toggle) => void;
  modelType: string;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
}));

const ToggleComponent = ({ toggle, setToggle, modelType }: Props) => {
  const { t } = useTranslation(['project']);
  const handleChangeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newToggleSelected: string,
  ) => {
    if (!newToggleSelected) return;
    if (toggle) setToggle({ ...toggle, selected: newToggleSelected });
  };
  return (
    <Paper
      elevation={0}
      sx={(theme) => ({
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        flexWrap: 'wrap',
      })}
    >
      <StyledToggleButtonGroup
        value={toggle?.selected}
        exclusive
        onChange={handleChangeToggle}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {toggle.values.map((toggleOption) => (
          <ToggleButton
            value={toggleOption}
            key={toggleOption}
            sx={{
              '&.Mui-selected': {
                color: 'black',
              },
            }}
          >
            {
              t(
                `project:results-page.${modelType}.toggle.option_${toggleOption}`,
              ) as string
            }
          </ToggleButton>
        ))}
        <Tooltip
          title={t(`project:results-page.${modelType}.toggle.information`)}
        >
          <InfoOutlinedIcon fontSize="small" color="action" />
        </Tooltip>
      </StyledToggleButtonGroup>
    </Paper>
  );
};

export default ToggleComponent;
