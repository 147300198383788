import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseBlock, { TitleBlock } from '../ui/Block';
import { PageContainer } from '../ui/Container';
import useReports from './context/useReports';
import CreateReportModal from './CreateReportModal';
import NewReportButtonDashboard from './NewReportButtonDashboard';
import ReportCard from './ReportCard';

const Block = styled(BaseBlock)`
  padding: 16px;
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const NewReportBlock = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #000000;
  border-radius: 8px;
  gap: 16px;
  height: 150px;
  min-width: 185px;
  cursor: pointer;

  &:hover {
    border: 1px solid #000000;
    cursor: pointer;
    p {
      text-decoration: underline;
    }
  }
`;
const Results = () => {
  const { t } = useTranslation([
    'dashboard',
    'common',
    'annual-reports',
    'model',
  ]);
  const navigate = useNavigate();
  const { reports, remove } = useReports();
  const [open, setOpen] = useState(false);

  if (!reports) return null;

  const handleNewProjectClicked = () => {
    setOpen(true);
  };

  const handleReportClicked = (reportId: string) => () => {
    navigate(`/reports/${reportId}`);
  };

  const handleReportDeleted = (reportId: string) => async (event: any) => {
    return await remove(reportId);
  };

  return (
    <PageContainer>
      <TitleBlock background="var(--reports-bg)">
        <h3>{t('annual-reports:title')}</h3>
        <p>{t('annual-reports:subtitle')}</p>
      </TitleBlock>
      <CreateReportModal
        open={open}
        setOpen={setOpen}
        onClose={() => {
          setOpen(false);
        }}
        onSave={() => {}}
      />
      <Block>
        <NewReportBlock onClick={handleNewProjectClicked}>
          <NewReportButtonDashboard onClick={handleNewProjectClicked} />
        </NewReportBlock>
        {reports.map((report) => (
          <ReportCard
            report={report}
            key={report._id.toString()}
            onClick={handleReportClicked(report._id.toString())}
            onDelete={handleReportDeleted(report._id.toString())}
          />
        ))}
      </Block>
    </PageContainer>
  );
};

export default Results;
