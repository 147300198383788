import {
  FormControl,
  MenuItem,
  Select as BaseSelect,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import useLanguages from './useLanguages';

const Select = styled(BaseSelect)`
  color: var(--button-secondary-text);
  --button-secondary-background-color: var(--button-secondary-text);

  svg {
    color: var(--button-secondary-text);
  }

  fieldset {
    border-color: var(--button-secondary-background-color) !important;
  }

  &:hover fieldset {
    border-color: var(--button-secondary-background-color) !important;
  }
`;

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation(['common']);
  const { languages } = useLanguages();

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    i18n.changeLanguage(event.target.value as string);
    window.location.reload();
  };

  if (!languages || !languages.length) return null;

  return (
    <FormControl size="small" color="secondary">
      <Select
        value={i18n.language.slice(0, 2)}
        onChange={handleChange}
        size="small"
        color="secondary"
      >
        {languages.map((language) => (
          <MenuItem key={language} value={language}>
            {t(`common:languages.${language}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
