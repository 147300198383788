import styled from '@emotion/styled';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../layout/LanguageSwitcher';
import Button from '../ui/Button';
import Markdown from '../ui/Markdown';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
  align-items: center;
  position: relative;
`;

const Block = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 800px;
  gap: 48px;
  padding: 16px;
  > div:first-of-type {
    width: 30%;
    height: 100%;
    background: white;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  > div:last-of-type {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`;

const LanguageSwitcherContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['authentication']);

  return (
    <>
      <LanguageSwitcherContainer>
        <LanguageSwitcher />
      </LanguageSwitcherContainer>
      <Container>
        <Block>
          <div>
            <img alt="Logo" src="/logo.png" />
          </div>
          <div>
            <Markdown>{t('authentication:home.welcome-message')}</Markdown>
            <Button
              variant="contained"
              onClick={() => navigate(`/authentication/register`)}
              color="primary"
            >
              {t('authentication:home.create-account')}
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(`/authentication/login`)}
              color="secondary"
            >
              {t('authentication:home.login')}
            </Button>
            {/* <BottomNote>
            <div
              dangerouslySetInnerHTML={{
                __html: configuration['home.bottomMessage'],
              }}
            ></div>
          </BottomNote> */}
          </div>
        </Block>
      </Container>
    </>
  );
};

export default Home;
