import styled from '@emotion/styled';

import { BarData, BarSingle1D, Model } from '@gameonly/core';
import {
  Bar,
  BarChart,
  Cell,
  ErrorBar,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import {
  NameType,
  ValueType,
} from 'recharts/src/component/DefaultTooltipContent';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import keyify from '../project/parameters/keyify';
import {
  SeedsTooltipComponent,
  SeedsTooltipRow,
} from './SeedsTooltipComponent';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const NoDataContainer = styled.div`
  width: 100%;
  padding: 16px;
  height: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
  }
`;

const getFormattedData = (data: BarData[], barData: BarData[]): BarData[] => {
  const dataFormatted: BarData[] = barData.map((barRow) => {
    const dataRow = data.find((d) => d.categoryName === barRow.name) as BarData;
    const value = dataRow[dataRow.categoryName];
    return {
      ...barRow,
      value,
    };
  });
  return dataFormatted;
};

const BarSingle1DComponent = ({
  result,
  model,
}: {
  result: BarSingle1D;
  model?: Model;
}) => {
  const { t } = useTranslation(['project', 'model']);
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${result.code?.toLowerCase()}`;
  const [data, setData] = useState<BarData[]>([]);

  useEffect(() => {
    if (result.data?.data && result.data?.barData) {
      setData(getFormattedData(result.data.data, result.data.barData));
    }
  }, [result.data]);

  if (!data || data.length === 0) {
    return (
      <NoDataContainer>
        <p>{t('project:results-page.result-empty')}</p>
      </NoDataContainer>
    );
  }
  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis tick={false} label="" />
          <YAxis />
          <Tooltip
            content={
              <CustomTooltipComponent
                unit={t(`${translationPrefix}.unit`)}
                translationPrefix={translationPrefix}
              />
            }
          />
          <Bar dataKey="value" name="Values">
            <ErrorBar dataKey="uncertaintyAbsolute" width={4} stroke="black" />
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

const CustomTooltipComponent = ({
  active,
  payload,
  unit,
  translationPrefix,
}: TooltipProps<ValueType, NameType> & {
  unit: string;
  translationPrefix: string;
}) => {
  const { t } = useTranslation(['project', 'model']);
  if (active && payload && payload.length > 0) {
    const data = payload[0];
    const category = `${data.dataKey}`;
    const uncertaintyRelative: number | undefined = data.payload.uncertaintyRelative
      ? (data.payload.uncertaintyRelative as number | undefined)
      : undefined;
    return (
      <SeedsTooltipComponent>
        <SeedsTooltipRow
          key={data.dataKey}
          color={data.color}
          category={t(`${translationPrefix}.data.${keyify(category)}`)}
          value={data.value?.toString()}
          unit={unit || ''}
          uncertaintyRelative={uncertaintyRelative}
        />
      </SeedsTooltipComponent>
    );
  }
  return null;
};

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
`;

export const BarSingle1DLegend = ({
  result,
  model,
}: {
  result: BarSingle1D;
  model?: Model;
}) => {
  const { t } = useTranslation(['project', 'model']);
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${result.code?.toLowerCase()}`;
  const unit = t(`${translationPrefix}.unit`);

  if (!result.data?.data || !result.data?.barData || !result) return null;

  const data = getFormattedData(result.data?.data, result.data?.barData);

  return (
    <LegendContainer>
      <p className="h6r underline">{t('project:results-page.legend')}</p>
      <div>
        {data?.map((entry) => {
          return (
            <>
              <SeedsTooltipRow
                key={entry.name}
                color={entry.color}
                category={t(`${translationPrefix}.data.${keyify(entry.name)}`)}
                value={entry.value}
                unit={unit}
                uncertaintyRelative={entry.uncertaintyRelative}
                // link={row.link}
              />
            </>
          );
        })}
      </div>
    </LegendContainer>
  );
};

export default BarSingle1DComponent;
