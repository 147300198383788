import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import AppLayout from '../layout/AppLayout';
import { HeaderProjects as Header } from '../layout/Headers';
import useModels from '../project/context/useModels';
import Loader from '../ui/Loader';
import ModelBlock from './blocks/model';
import ReportBlock from './blocks/report';

const Layout = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  min-height: calc(100vh - 64px);
  flex-grow: 1;
  gap: 20px;
  max-width: 1400px;
  width: 100%;
`;

const CncContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  padding: 16px;
  flex-grow: 1;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100px;
  gap: 20px;
`;

const TopLeft = styled.div``;

const TopRight = styled.div`
  flex-grow: 1;
  display: flex;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100px;
  flex-grow: 2;
  gap: 20px;
`;

const Dashboard = () => {
  const { models, loading } = useModels();

  const officeModel = models?.find((model) => model.type === 'site');
  const gameModel = models?.find((model) => model.type === 'game');
  const cncModel = models?.find((model) => model.type === 'cnc');

  return (
    <AppLayout header={<Header />}>
      <Helmet>
        <title>{`TRANSITION ENVIRONNEMENTALE DE LA FILIÈRE DU JEU VIDÉO`}</title>
      </Helmet>
      {!models && loading && <Loader fullPage />}
      {models && !loading && (
        <>
          <Layout>
            <Top>
              <TopLeft>
                <ReportBlock />
              </TopLeft>
              {officeModel && (
                <TopRight>
                  <ModelBlock
                    model={officeModel}
                    limit={4}
                    sortOrder="date"
                    className="one-line"
                  />
                </TopRight>
              )}
            </Top>
            <Bottom>
              {gameModel && (
                <ModelBlock model={gameModel} limit={20} sortOrder="date" />
              )}
            </Bottom>
          </Layout>
          {cncModel && (
            <CncContainer>
              <ModelBlock model={cncModel} limit={20} sortOrder="date" />
            </CncContainer>
          )}
        </>
      )}
    </AppLayout>
  );
};

export default Dashboard;
