import styled from '@emotion/styled';
import { Model, Pie1D, Pie1DData } from '@gameonly/core';
import { useTranslation } from 'react-i18next';
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/src/component/DefaultTooltipContent';
import keyify from '../project/parameters/keyify';
import {
  SeedsTooltipComponent,
  SeedsTooltipRow,
} from './SeedsTooltipComponent';

interface CustomTooltip extends TooltipProps<ValueType, NameType> {
  unit: string | undefined;
}

const CustomTooltipComponent = ({
  active,
  payload,
  unit,
}: CustomTooltip): JSX.Element => {
  if (active && payload && payload.length > 0) {
    return (
      <SeedsTooltipComponent>
        <SeedsTooltipRow
          color={payload[0].payload.fill}
          category={payload[0].payload.name}
          value={payload[0].payload.value}
          unit={unit}
        />
      </SeedsTooltipComponent>
    );
  }
  return <></>;
};

const Pie1DComponent = ({
  result,
  model,
}: {
  result: Pie1D;
  model?: Model;
}) => {
  const { t } = useTranslation(['project', 'model']);
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${result.code?.toLowerCase()}`;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={result.data}
          cx={'50%'}
          cy={'50%'}
          innerRadius="0%"
          outerRadius="100%"
          dataKey="value"
        />
        <Tooltip
          content={
            <CustomTooltipComponent unit={t(`${translationPrefix}.unit`)} />
          }
          allowEscapeViewBox={{ x: true, y: true }}
          wrapperStyle={{ zIndex: 1000 }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
`;

export const Pie1DLegend = ({
  result,
  model,
}: {
  result: Pie1D;
  model?: Model;
}) => {
  const { t } = useTranslation(['project', 'model']);
  const data = JSON.parse(
    JSON.stringify(result.data?.sort((a, b) => b.value - a.value)),
  );
  const total = data.reduce(
    (total: number, row: Pie1DData) => total + row.value,
    0,
  );
  const translationPrefix = `model:${model?.name.toLowerCase()}.result.${result.code?.toLowerCase()}`;

  return (
    <LegendContainer>
      <p className="h6r underline">{t('project:results-page.legend')}</p>
      <div>
        {data.map((row: Pie1DData) => (
          <>
            {row.value > 0 && (
              <SeedsTooltipRow
                key={row.name}
                link={row.link}
                color={row.fill}
                category={
                  row.name.startsWith('#')
                    ? row.name.slice(1)
                    : t(`${translationPrefix}.data.${keyify(row.name)}`)
                }
                value={row.value.toString()}
                unit={t(`${translationPrefix}.unit`)}
                percent={row.value / total}
              />
            )}
          </>
        ))}
      </div>
    </LegendContainer>
  );
};

export default Pie1DComponent;
