import styled from '@emotion/styled';
import { ReactComponent as Add } from '../ui/icons/add.svg';
import { useTranslation } from 'react-i18next';

const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
`;

const NewReportButtonDashboardContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
`;

const NewReportButtonDashboard = ({
  onClick,
}: {
  onClick?: (model: string) => void;
}) => {
  const { t } = useTranslation(['annual-reports']);
  return (
    <NewReportButtonDashboardContainer>
      <IconContainer>
        <Add />
      </IconContainer>
      <p className="hxb">{t("annual-reports:add-report")}</p>
    </NewReportButtonDashboardContainer>
  );
};

export default NewReportButtonDashboard;
