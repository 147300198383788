import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ResultDispatch } from '../results/Results';
import Block, { TitleBlock } from '../ui/Block';
import { PageContainer } from '../ui/Container';
import useReport from './context/useReport';
import { Configuration, Model } from '@gameonly/core';

const ReportTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Report = () => {
  const { reportId } = useParams();
  const { report, rename } = useReport(reportId);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['annual-reports']);

  if (!report) {
    return null;
  }

  const handleEditClicked = async () => {
    let newName = prompt('Renommer le rapport ?', report.name);
    if (!newName) return;
    try {
      await rename(newName);
      enqueueSnackbar('Le rapport a bien été renommé', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Une erreur est survenue', {
        variant: 'error',
      });
    }
  };


  const model: Model = {
    name: 'annual-reports',
    _id: {} as Model['_id'],
    hasRequiredFields: false,
    singularName: '',
    type: '',
    color: '',
    description: '',
    spreadsheetId: '',
    parameters: [],
    config: {} as Configuration,
    defaultLanguage: '',
  };


  return (
    <PageContainer>
      <TitleBlock background="var(--reports-bg)">
        <ReportTitle>
          {report.name}
          <IconButton onClick={handleEditClicked}>
            <EditIcon />
          </IconButton>
        </ReportTitle>
        <p>{t('annual-reports:report.subtitle')}</p>
      </TitleBlock>
      <Block>
        {report.results.map((result, index) => (
          <ResultDispatch result={result} key={index} model={model}/>
        ))}
      </Block>
    </PageContainer>
  );
};

export default Report;
