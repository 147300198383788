import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Collapse,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';

const RadioSelect = ({
  possibleValues,
  ...props
}: RadioGroupProps & { possibleValues: string[] }) => (
  <RadioGroup
    {...props}
    sx={{
      flexDirection: 'row',
      '.MuiFormControlLabel-label': {
        margin: '4px',
      },
    }}
  >
    {possibleValues.map((possibleValue) => (
      <FormControlLabel
        key={possibleValue}
        value={possibleValue}
        control={<Radio />}
        label={possibleValue}
      />
    ))}
  </RadioGroup>
);

export const MeasureTypeSelect = ({
  possibleValues,
  onChange,
  defaultValue
}: RadioGroupProps & { possibleValues: string[] }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isCollapsed, setIsCollapsed] = useState(Boolean(defaultValue));

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    setIsCollapsed(true);
    onChange && onChange(event, value);
  };

  const handleExpand = () => setIsCollapsed(false);

  return (
    <Box>
      {isCollapsed ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: 'primary.main',
          }}
          onClick={handleExpand}
        >
          <Typography>{selectedValue}</Typography>
          <IconButton>
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : null}

      <Collapse in={!isCollapsed} timeout="auto">
        <RadioSelect
          possibleValues={possibleValues || []}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      </Collapse>
    </Box>
  );
};

export default RadioSelect;
