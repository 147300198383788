import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SeedsTooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    gap: 6px;
    padding: 12px;
    background-color: var(--backgroundColor);
  }
`;

export const SeedsTooltipComponent = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <SeedsTooltipContainer>{children}</SeedsTooltipContainer>;
};

const SeedsTooltipRowContainer = styled.div`
    display: flex;
    align-items: center;
    .square_color {
        width: 12px;
        height: 12px;
        margin-right: 8px;
    }
  }
`;

interface SeedsTooltipProps {
  color: string | undefined;
  category: string | undefined;
  value: string | undefined;
  unit: string | undefined;
  percent?: number;
  link?: string;
  uncertaintyRelative?: number | undefined;
}
function isNumber(value?: string | number): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
}
const getHumanizedValue = (value?: string | number) => {
  let humanizedValue = value;

  if (isNumber(value)) {
    if (Number(value) < 0.01 || Number(value) > 1000000) {
      humanizedValue = Number(value).toExponential(2);
      if (humanizedValue.includes('e+0')) {
        humanizedValue = humanizedValue.replace('e+0', '');
      }
    } else {
      humanizedValue = Number(value).toFixed(0);
    }
  }
  return humanizedValue;
};
export const SeedsTooltipRow = ({
  color,
  category,
  value,
  unit,
  percent,
  link,
  uncertaintyRelative,
}: SeedsTooltipProps) => {
  const { t } = useTranslation(['common']);
  return (
    <SeedsTooltipRowContainer>
      <div className="square_color" style={{ backgroundColor: color }} />
      <p className="hxr">
        {link ? (
          link[0] === '#' ? (
            <>
              <a href={link}>{category}</a> :{' '}
              <HumanizedValueWithUncertainty
                value={value}
                uncertaintyRelative={uncertaintyRelative}
                unit={unit}
              />
            </>
          ) : (
            <>
              <Link to={link}>{category}</Link> :{' '}
              <HumanizedValueWithUncertainty
                value={value}
                uncertaintyRelative={uncertaintyRelative}
                unit={unit}
              />
            </>
          )
        ) : (
          <>
            {category} :{' '}
            <HumanizedValueWithUncertainty
              value={value}
              uncertaintyRelative={uncertaintyRelative}
              unit={unit}
            />
          </>
        )}
      </p>
      {percent && (
        <p className="hxr">
          , {t('common:or-percentage')}{' '}
          <b>{Math.round(Number(percent) * 100)}</b> %
        </p>
      )}
    </SeedsTooltipRowContainer>
  );
};

interface HumanizedValueProps {
  value: string | undefined;
  unit: string | undefined;
  uncertaintyRelative: number | undefined;
}

const HumanizedValueWithUncertainty = ({
  value,
  uncertaintyRelative,
  unit,
}: HumanizedValueProps) => {
  const humanizedValue = getHumanizedValue(value);
  const humanizedUncertainty = uncertaintyRelative
    ? getHumanizedValue(uncertaintyRelative)
    : false;

  return (
    <>
      <b>{humanizedValue}</b>
      {unit && ` ${unit}`}
      {humanizedUncertainty && (
        <>
          {' '}
          ± <b>{humanizedUncertainty}%</b>
        </>
      )}
    </>
  );
};

interface SimpleTooltipProps {
  color: string | undefined;
  category: string | undefined;
  unit: string | undefined;
  link?: string;
}

export const SimpleTooltipRow = ({
  color,
  category,
  unit,
  link,
}: SimpleTooltipProps) => {
  return (
    <SeedsTooltipRowContainer>
      <div className="square_color" style={{ backgroundColor: color }} />
      <p className="hxr">
        {link ? (
          link[0] === '#' ? (
            <>
              <a href={link}>{category}</a> ({unit})
            </>
          ) : (
            <>
              <Link to={link}>{category}</Link> ({unit})
            </>
          )
        ) : (
          <>
            {category} ({unit})
          </>
        )}
      </p>
    </SeedsTooltipRowContainer>
  );
};
