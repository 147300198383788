import styled from '@emotion/styled';
import { Configuration, GlobalScore, Model, Nav, Pie1D } from '@gameonly/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import useReport from '../../../reports/context/useReport';
import { ResultDispatch } from '../../../results/Results';
import { scopeIcons } from '../../../results/ResultsSimple';
import Button from '../../../ui/Button';
import {
  BlockContainer as BaseBlockContainer,
  BlockContent as BaseBlockContent,
  BlockHeader,
} from '../../ui';
const BlockContainer = styled(BaseBlockContainer)``;

const BlockContent = styled(BaseBlockContent)`
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    min-height: 100px;
    width: 140px !important;
  }
`;

const SelectorContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  svg {
    height: 22px;
    width: 22px;
  }
`;

const ReportBlock = () => {
  const { t } = useTranslation(['dashboard', 'model', 'common']);
  const { report } = useReport('latest');
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useLocalStorage<number>(
    'selectedCategory',
    0,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const nav = report?.results.find((result) => result.type === 'nav') as Nav;

  const categories = nav?.data || [];

  const displayableResults =
    report?.results
      .filter((result) => ['pie1D', 'globalScore'].includes(result.type))
      .map((result) => result as Pie1D | GlobalScore) || [];

  const results = displayableResults
    .filter((result) => result.scope === categories[selectedCategory].link)
    .map((result) => {
      return {
        ...result,
        code: [result.code, result.scope].filter((code) => !!code).join('-'),
      };
    });

  const handleButtonClicked = () => {
    navigate('/reports');
  };
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryClicked =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setSelectedCategory(index);
      setAnchorEl(null);
    };

  const model: Model = {
    name: 'annual-reports',
    _id: {} as Model['_id'],
    hasRequiredFields: false,
    singularName: '',
    type: '',
    color: '',
    description: '',
    spreadsheetId: '',
    parameters: [],
    config: {} as Configuration,
    defaultLanguage: '',
  };

  return (
    <BlockContainer>
      <BlockHeader>
        <div>{t('dashboard:annual-reports.block.title')}</div>
      </BlockHeader>
      <BlockContent color="#fed169">
        {categories.length > 1 && (
          <SelectorContainer>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {categories.map((category, index) => {
                const icon = scopeIcons[category.icon!];
                return (
                  <MenuItem onClick={handleCategoryClicked(index)}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>
                      {t(
                        `dashboard:annual-reports.categories.${category.icon}`,
                      )}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </SelectorContainer>
        )}
        {!!results?.length && (
          <>
            <ResultsContainer>
              {results.map((result, index) => (
                <ResultDispatch
                  result={result}
                  key={index}
                  dashboard
                  model={model}
                />
              ))}
            </ResultsContainer>
            <Button onClick={handleButtonClicked}>
              {t('dashboard:annual-reports.block.see-all')}
            </Button>
          </>
        )}
        {!results?.length && (
          <>
            <p style={{ minHeight: 100 }}>
              {t('dashboard:annual-reports.block.empty')}
            </p>
            <Button onClick={handleButtonClicked}>
              {t('dashboard:annual-reports.block.add')}
            </Button>
          </>
        )}
      </BlockContent>
    </BlockContainer>
  );
};

export default ReportBlock;
