import {
  MenuItem,
  Select as MuiSelect,
  SelectProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const Select = ({
  possibleValues,
  ...props
}: SelectProps & { possibleValues: string[] }) => {
  const { t } = useTranslation(['common']);
  return (
    <MuiSelect {...props}>
      <MenuItem key={''} value={''} sx={{ color: 'text.secondary' }}>
        {t('common:not-specified')}
      </MenuItem>
      {possibleValues.map((possibleValue) => (
        <MenuItem key={possibleValue} value={possibleValue}>
          {possibleValue}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};
export default Select;
